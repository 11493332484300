@import 'src/styles/vars';

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100%;
}

.modalHeader:global(.modal-body) {
  height: auto;
}

.body:global(.modal-body) {
  padding: 30px 30px 15px;
  color: $text-color;
}

.modalHeader:global(.modal-body),
.footer:global(.modal-footer) {
  padding: 12px 30px;
}

.header {
  line-height: 26px;
  font-size: 24px;
  padding: 0;
  margin: 0;
  color: $text-color;
}

.label {
  line-height: 18px;
  font-size: 16px;
  color: $text-color;
  width: 100%;
}

.formRow {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.formCell {
  position: relative;
  flex: 1 1 50%;
  display: inline-flex;
  flex-direction: column;
  padding-right: 20px;

  &:last-child {
    padding-right: 0;
  }
}

.formItem {
  position: relative;

  & :global(.ant-input-number) {
    width: unset;
    max-width: 240px;
  }
}

.modalLink {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;

  & .label {
    display: block;
    width: auto;
    padding-right: 20px;
    margin-bottom: 0;
  }
}

.uploadedFilesList:global(.ant-form-item) {
  margin-top: 20px;
}

.existingFileLabel {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.upload {
  position: relative;
  & :global(.ant-btn) {
    width: 250px;
  }
  & :global(.ant-upload-list) {
    margin-bottom: 20px;
  }
}

.radio,
.checkBoxGroup :global(.ant-checkbox-group-item) {
  display: flex;
}

.avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.img{
  display: block;
  max-width: 100px;
  transition: 0.3s ease-in-out all;
}
.img:hover{
  img {
    transform: scale(1.05);
  }
}
.cantChange {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
}
.realisation {
  .formRow {
    margin-top: 10px;
  }
  .title {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.inputDatepicker {
  max-height: 36px;
  padding: 4px 8px;
  border-radius: 6px;
  border: 1px solid #662482;
  color: #662482;
  width: 100%;
  max-width: 378px;
}
.isConfidential {
  display: flex;
  align-items: center;
  gap: 5px;

}
.error {
  border: 1px solid $coral !important;
  :global(.ql-editor) {
    border: 1px solid $coral !important;
  }
}
.errorEditor {
  :global(.ql-editor) {
    border: 1px solid $coral !important;
  }
}
.errorText {
  display: block;
  color: $coral;
}