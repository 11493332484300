@import 'src/styles/vars';

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
}
.icon {
  width: 20px;
  height: 20px;
  display: block;
  object-fit: cover;
  cursor: pointer;
}
