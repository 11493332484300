@import 'src/styles/vars';

.confidencial {
  &:global(.isAbsolute) {
    position: absolute;
  }
  &:global(:not(.isAbsolute)) {
    text-align: right;
    width: 100%;
  }

  right: 0;
  top: 10px;
  color: $text-color-dark;
  @media (max-width: 665px) {
    left: 0;
    top: -35px;
  }
}