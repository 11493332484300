$base-color: #fff;

$primary-color: #0061D9;
$secondary-color: #D0F053;

$accent-color-light: #EAEAEA;
$accent-color: #525252;
$accent-color-dark: darken($accent-color, .15);

$gray: #A7A7AB;
$text-color: #333333;
$text-color-light: #D0F053;
$text-color-dark: #000;
$text-color-alt: #fff;

$border-color-light: #D4D4D4;
$border-color: #333;
$border-color-dark: darken($border-color, .15);

$avatar-background: #F2F5F7;
$background-light: #F2F2F2;

$accent-green: #009587;
$accent-orange: #F15A24;
$accent-yellow: #f1ad24;
$accent-red: #ff4d4f;
$red: #ff4d4f;

$input-height: 36px;
$max-input-width: 378px;

$header-height: 160px;
$sidebar-width: 340px;

$dark-blue: #0050B2;
$blue: #0061D9;
$green: #84BD00;
$green-yellow: #D0F053;
$coral: #FF2E56;
$light-orange: #FFC700;
$main: #333333;

$height: clamp(700px, 100vh, 100vh);

$xxl: 1400px;
$xl: 1200px;
$lg: 991px;
$md: 768px;
$sm: 576px;