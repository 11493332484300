@import 'src/styles/vars';

.videoContent:global(.ant-badge) {
  color: $secondary-color;
}

.blogSphere:global(.ant-badge) {
  color: $text-color-light;
}

.multiFormat:global(.ant-badge) {
  color: $accent-orange;
}
