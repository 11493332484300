@import 'src/styles/vars';
.container {
  display: flex;
  padding: 15px 75px 15px 15px;
  @media (max-width: $lg) {
    flex-direction: column;
    padding: 30px;
  }
  @media (max-width: $md) {
    flex-direction: column;
    padding: 20px;
  }
  .return {
    width: 100px;
    margin-top: 10px;
    :global(header) {
      position: sticky;
      top: 15px;
    }
  }
  .content {
    width: 100%;
    position: relative;
    &.contentColumnCollapsed {
      padding-right: $sidebar-width;
    }
    .document {
      margin-bottom: 8px;
      cursor: pointer;
      .pdfImg {
        height: 35px;
        width: 35px;
        margin-right: 28px;
      }
      .documentImg {
        height: 22px;
        width: 22px;
        margin-left: 28px;
      }
    }
    .video {
      max-width: 100%;
    }
    .nav {
      display: flex;
      align-items: center;
      gap: 10px;
      .title {
        margin-bottom: 0;
        line-height: 22px;
        font-size: 18px;
        font-weight: 600;
        color: $main;
        min-width: 110px;
      }
    }
  }
}

.rightColumn {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex: 1 0 $sidebar-width;
  min-height: 100%;
  transition: right 300ms ease-in-out;

  &.rightColumnClosed {
    right: -$sidebar-width;
  }
}

.container {
  :global(.ant-collapse) {
    background: #fff;
    border: none;
  }
  :global(.ant-collapse-content) {
    > & :global(.ant-collapse-content-box) {
      padding: 5px;
    }
  }
  :global(.ant-collapse) {
    & > :global(.ant-collapse-item) {
      border-bottom: none;
      margin-bottom: 15px;
    }

  }
  :global(.ant-collapse-header) {
    border: 1px solid #C2D1D9;
    border-radius: 10px;
  }
  :global(.ant-collapse) {
    & > :global(.ant-collapse-item:last-child) {
      & > :global(.ant-collapse-header) {
        border: 1px solid #C2D1D9;
        border-radius: 10px !important;
      }
    }

  }
  :global(.ant-collapse-content) {
    border-top: none;
  }
}
.image {
  min-width: 100%;
  max-width: 100%;
}


.tab {
  display: flex;
  flex-direction: column;
  .tabInfo {
    display: flex;
    justify-content: space-between;
    h3 {
      color: $main;
    }
  }
  .add {
    margin-bottom: 30px;
  }
  :global(.ant-collapse) > :global(.ant-collapse-item) > :global(.ant-collapse-header),
  :global(.ant-collapse) > :global(.ant-collapse-item:last-child) > :global(.ant-collapse-header){
    border: none;
    color: $main;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
  :global(.ant-collapse-content-box) {
    color: rgba(0, 0, 0, 0.5);
  }
  .tabCard {
    display: flex;
    width: 100%;
    .tabCardLeft {
      width: 40%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .tabCardRight {
      width: 60%;
      padding-left: 30px;
      strong {
        padding-left: 15px;
      }
    }
  }
}
@media (max-width: 990px) {
  .tab {
    .tabCard {
      flex-wrap: wrap;
      .tabCardLeft {
        width: 100%;
      }
      .tabCardRight {
        width: 100%;
        padding-left: 0;
      }
    }
  }
}

