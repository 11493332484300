@import "src/styles/vars";
.container {
  color: #333333;
  display: flex;
  gap: 30px;
  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
  @media (max-width: $md) {
    flex-direction: column;
  }
}
.left {
  width: 30%;
  overflow: hidden;
  @media (max-width: 900px) {
    width: 100%;
  }
  .name {
    font-size: 26px;
    line-height: 30px;
    font-weight: 600;
  }
  .owner {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    margin-top: 10px;
  }
  .item {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    &__title {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 8px;
    }
    &__ul {
      margin: 0;
      padding: 0;
      list-style: none;
      gap: 8px;
    }
    :global(li) {
      font-size: 16px;
      font-weight: 400;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__value {
      border-radius: 5px;
      padding: 0 5px;
      &:global(.red) {
        background-color: $coral;
      }
      &:global(.green) {
        background-color: $green-yellow;

      }
    }
  }
  .box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 6px;
    :global(strong) {
      white-space: nowrap;
    }
  }
  .internetResources {
    margin-top: 10px;
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 10px;
  }
  .recommendedCostReduction {
    font-size: 16px;
    line-height: 1.1;
    padding: 5px 10px;
    font-weight: 300;
    color: $main;
    background-color: $green-yellow;
    text-wrap: balance;
    :global(span) {
      white-space: nowrap;
    }
  }
}
.info {
  margin-top: 16px;
  :global(strong) {
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
  }
  :global(span) {
    font-size: 16px;
    line-height: 18px;
  }
  :global(p) {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 18px;
  }
}
.right {
  flex: 70%;
  @media (max-width: 900px) {
    width: 100%;
  }
  .image {
    width: 100%;
    .img {
      min-width: 100%;
      max-width: 100%;
      aspect-ratio: 16/9;
    }
  }
}