@import 'src/styles/vars';

.cell {
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.item {
  display: flex;
  align-items: center;
  gap: 4px;
  color: $gray;
  &:global(.active) {
    color: $main;
  }
}
.bullet {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: $accent-color;
}

