@import 'src/styles/vars';

.container {
  display: flex;
  padding: 15px 75px 15px 15px;
  @media (max-width: $lg) {
    flex-direction: column;
    padding: 30px;
  }
  @media (max-width: $md) {
    flex-direction: column;
    padding: 20px;
  }
  .return {
    width: 40px;
    margin-top: 12px;
    :global(header) {
      position: sticky;
      top: 15px;
    }
  }
  .content {
    width: 100%;
    position: relative;

    .document {
      margin-bottom: 8px;
      cursor: pointer;
      .pdfImg {
        height: 35px;
        width: 35px;
        margin-right: 28px;
      }
      .documentImg {
        height: 22px;
        width: 22px;
        margin-left: 28px;
      }
    }
    .video {
      max-width: 100%;
    }
  }
}
