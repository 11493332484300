@import 'src/styles/vars';

.cell {
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  color: $gray;
  &:global(.active) {
    color: $main;
  }
}
.text {

}
.icon {
  width: 25px !important;
  min-width: 25px !important;
}
.active {
  color: $accent-green;
}
.against {
  color: $accent-orange;
}
.justVoted {
  color: $accent-yellow;
}

