.slideCountdown {
  display: inline-block;
  font-weight: bold;
}

.slide-block-time {
  float: left;
  font-size: 40px;
  width: 70px;
  margin: 0 15px 0 15px;
  &.weeks {
    width: 100px;
    .tens {
      min-width: 20px;
    }
  }

  > .title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
  }

  .wrap-stage {
    > .stage {
      float: left;
      width: 50%;
      overflow: hidden;
      height: 40px;
      position: relative;

      > .mask {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 80px;

        > div {
          height: 40px;
          line-height: 40px;
        }
      }
    }
  }
}
