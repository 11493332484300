@import 'src/styles/vars';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  color: $main;
  padding: 18px 25px 50px 25px;
}

.topBar {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.toParentPage {
  margin-right: 20px;
}

.pageTitle {
  line-height: 45px;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 40px;
}

.heading {
  line-height: 22px;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  border-bottom: 1px solid rgba($accent-color, .3);
  padding: 0 0 20px 0;
  margin: 20px 0;
}

.formItem {
  position: relative;
  max-width: 240px;

  & :global(.ant-input-number) {
    width: unset;
    max-width: 240px;
  }
}

.label {
  line-height: 18px;
  font-size: 16px;
  color: $main;
  width: 100%;
}

.formRow {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.formCell {
  position: relative;
  flex: 1 1 50%;
  max-width: 240px;
  display: inline-flex;
  flex-direction: column;
  margin-right: 20px;
  box-sizing: content-box;

  &:last-child {
    margin-right: 0;
  }
}

.table {
  width: 100%;
  margin-bottom: 20px;
}

.usersList {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  padding: 0 20px 10px 20px;
}

.listHeading {
  line-height: 18px;
  font-size: 15px;
  font-weight: 600;
  color: rgba($text-color-dark, .4);
}

.usersListItem {
  color: $main;
}

.listHeading,
.usersListItem {
  padding: 9px;
  border-bottom: 1px solid rgba($accent-color, .3);
}

.fio,
.login {
  display: block;
}

.add {
  margin: 0 10px;
}

.projectsList {
  padding: 0 20px 10px 20px;
}

.blocksList {
  display: flex;
  flex-direction: column;
  padding: 0 20px 10px 20px;
}

.blockItem {
  position: relative;
  max-width: 500px;
}

.blockHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.blockActions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 10px;

  & :global(.ant-btn) {
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }
  }
}
.blockBody {
  padding: 10px;
  margin: 5px 0;
  border: 1px solid rgba($accent-color, .3);
}
.blockQuestion {
  margin-bottom: 15px;
}
.blockFiles {
  padding: 4px;
}

.document {
  margin-bottom: 8px;
  cursor: pointer;
}

.pdfImg {
  height: 35px;
  width: 35px;
  margin-right: 28px;
}

.documentImg {
  height: 22px;
  width: 22px;
  margin-left: 28px;
}

.activateBlock {
  margin: 20px 0;

  & :global(.ant-switch) {
    margin-right: 10px;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  & :global(.ant-btn) {
    margin-left: 10px;
  }
}

.dateError {
  position: absolute;
  height: 24px;
  width: 100%;
  color: $accent-red;
  bottom: -26px;
  left: 0;
  line-height: 12px;
  font-size: 11px;
  vertical-align: middle;
  overflow: hidden;
  word-break: break-all;
  line-break: auto;
  padding: 0 6px;
}
