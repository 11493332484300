@import "src/styles/vars";
.item {
  position: relative;
  width: 100%;
  margin-bottom: 40px;
  composes: flex-column from global;

}
.title {
  line-height: 22px;
  font-size: 18px;
  font-weight: 600;
  color: $main;
  margin-bottom: 20px;
  min-width: 110px;
  span {
    margin-left: 15px;
    font-size: 18px;
    color: #000;
  }
}
.document {
  margin-bottom: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  &:hover {
    color: $blue;
  }
}
.pdfImg {
  height: 35px;
  width: 35px;
  margin-right: 28px;
}
.documentImg {
  height: 22px;
  width: 22px;
}
