@import "src/styles/vars";
.button {
  margin-top: 8px;
  display: flex;
  align-items: flex-end;
  gap: 8px;
  color: #B8B7B7;
  cursor: pointer;
  transition: 0.3s ease all;
  font-size: 18px;
  line-height: 20px;
  &:hover {
    color: $blue;
  }
}
.icon {
  width: 18px;
  height: 18px;
}